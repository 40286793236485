<template>
  <router-link @click.native="addAssets(assets)" v-bind="$attrs">
    <slot></slot>
  </router-link>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapMutations } = createNamespacedHelpers("breadcrumb");

export default {
  props: { assets: { type: Object, default: () => {} } },
  methods: { ...mapMutations(["addAssets"]) }
};
</script>

<style scoped></style>
