<template>
  <Tooltip :content="$t(auth0.labelKey)">
    <Tag type="border" :color="auth0.color" class="p-1">
      <img class="h-4" src="@/assets/auth0.png" alt="Logo Auth0" />
    </Tag>
  </Tooltip>
</template>

<script>
export default {
  props: {
    m2mClientId: { Type: String, default: null },
    userId: { Type: String, default: null }
  },
  computed: {
    auth0() {
      return this.m2mClientId
        ? { color: "primary", labelKey: "AUTH0_M2M" }
        : this.userId
        ? { color: "success", labelKey: "AUTH0_USER" }
        : { color: "default", labelKey: "AUTH0_NO_ACCESS" };
    }
  }
};
</script>

<style scoped></style>
