<template>
  <Tooltip :content="$t(userId ? 'DAS_ACCESS' : 'NO_DAS_ACCESS')">
    <Tag type="border" :color="userId ? 'success' : 'error'" class="p-1">
      <fa :icon="['fal', 'atom-alt']" size="lg" class="mb-1 text-gray-700"></fa>
    </Tag>
  </Tooltip>
</template>

<script>
export default {
  props: { userId: { type: String, default: null } }
};
</script>

<style scoped></style>
