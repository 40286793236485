<template>
  <div>
    <div class="flex justify-center">
      <Input
        :value="search"
        @input="handleSearch"
        size="large"
        class="w-1/4"
        search
        clearable
        :placeholder="$t('USER')"
        :readonly="$apollo.queries.users.loading"
      />
      <ExportUsers :tenant-id="organization ? organization.id : undefined" />
    </div>
    <Loader :loading="$apollo.loading">
      <Error :error="error">
        <div class="card-container py-6 px-20">
          <OnboardUserCard />
          <UserCard
            v-for="{ id, ...rest } in users"
            :key="id"
            v-bind="{ id, ...rest }"
          />
        </div>
        <div
          v-show="!users.length"
          class="text-gray-400 h-64 flex items-center justify-center"
        >
          <div class="inline-block text-center">
            <fa icon="inbox" size="6x"></fa><br />
            <span class="text-base">
              {{ $t("SORRY_NOTHING_TO_DISPLAY_HERE") }}
            </span>
          </div>
        </div>
      </Error>
    </Loader>
    <Page
      v-if="!organization"
      class="text-center mt-2"
      :current="page.current"
      :page-size="page.size"
      :total="page.totalEntries"
      @on-change="current => (page.current = current)"
      @on-page-size-change="size => (page.size = size)"
      show-sizer
      :disabled="$apollo.queries.users.loading"
    />
  </div>
</template>

<script>
import usersQuery from "./queries/users.gql";
import OnboardUserCard from "./components/OnboardUserCard/OnboardUserCard.vue";
import UserCard from "./components/UserCard/UserCard.vue";
import ExportUsers from "./components/ExportUsers/ExportUsers.vue";
import Loader from "@/components/Loader/Loader.vue";
import Error from "@/components/Error/Error.vue";
import { debounce } from "lodash";

export default {
  components: { OnboardUserCard, UserCard, Loader, Error, ExportUsers },
  props: { organization: Object },
  data() {
    return {
      users: this.organization?.users ?? [],
      search: "",
      page: { current: 1, size: 10, totalEntries: 0 },
      error: null
    };
  },
  apollo: {
    users: {
      query: usersQuery,
      variables() {
        const { search, page } = this;
        const { current, size } = page;

        return { search, page: { current, size } };
      },
      update({ users: { entries = [], pageMeta = {} } = {} }) {
        Object.assign(this.page, pageMeta);
        return entries;
      },
      error({ graphQLErrors, networkError }) {
        this.error = JSON.stringify(graphQLErrors || networkError);
      },
      skip() {
        return !!this.organization;
      }
    }
  },
  methods: {
    handleSearch: debounce(function(search) {
      this.search = search;
    }, 500)
  }
};
</script>

<style scoped>
.card-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 320px);
  grid-gap: 2rem;
  justify-content: space-around;
}
</style>
