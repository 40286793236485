<template>
  <Tooltip :disabled="!lastLogin.inactive || !lastLogin.date" max-width="300">
    <span :class="{ 'text-red-600': lastLogin.inactive }">
      {{ lastLogin.date || $t("NEVER") }}
      <fa v-if="lastLogin.inactive" icon="exclamation"></fa>
    </span>
    <div
      slot="content"
      class="whitespace-normal text-center not-italic"
      style="width: 280px"
    >
      {{ $t("NO_LOGIN_WITHIN_LAST_X_DAYS", { days: inactiveDays }) }}
    </div>
  </Tooltip>
</template>

<script>
export default {
  props: {
    date: { type: String, default: null },
    inactiveDays: { type: Number, default: 7 }
  },
  computed: {
    lastLogin() {
      const { date, inactiveDays } = this;
      const lastLogin = date && new Date(date);
      const inactiveDaysAgo = new Date();
      inactiveDaysAgo.setDate(inactiveDaysAgo.getDate() - inactiveDays);

      return {
        date: lastLogin && lastLogin.toLocaleDateString(),
        inactive: !lastLogin || lastLogin < inactiveDaysAgo
      };
    }
  }
};
</script>

<style scoped></style>
