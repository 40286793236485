<template>
  <Button @click="exportUsers" class="ml-2" size="large" :loading="loading">
    <fa icon="file-download" class="mr-2" /> {{ $t("EXPORT") }}
  </Button>
</template>

<script>
import usersQuery from "./queries/users.gql";
import tenantUsersQuery from "./queries/tenantUsers.gql";
import { saveAs } from "file-saver";

export default {
  props: { tenantId: { type: String } },
  data: () => ({ loading: false }),
  methods: {
    exportUsers() {
      this.loading = true;

      this.$apollo
        .query({
          query: this.tenantId ? tenantUsersQuery : usersQuery,
          ...(this.tenantId && { variables: { id: this.tenantId } })
        })
        .then(({ data }) => {
          const users = this.tenantId ? data.tenant.users : data.users.entries;

          const csvContent = users.reduce(
            (acc, { name, email, roles }) =>
              email.includes("@delight-data.com")
                ? acc
                : `${acc}"${name}","${email}","${roles[0]?.tenant.name ??
                    ""}","${this.$t(
                    roles.some(({ name }) => name.includes("ignition"))
                      ? "YES"
                      : "NO"
                  )}","${this.$t(
                    roles.some(({ name }) => name.includes("activation"))
                      ? "YES"
                      : "NO"
                  )}","${this.$t(
                    roles.some(({ name }) => name.includes("relation"))
                      ? "YES"
                      : "NO"
                  )}"\n`,
            `"${this.$t("NAME")}","${this.$t("EMAIL")}","${this.$t(
              "ORGANIZATION"
            )}","Ignition","Activation","Relation"\n`
          );

          saveAs(
            new Blob([csvContent], { type: "text/csv;charset=utf-8" }),
            `${this.$t("USERS")}.csv`
          );
        })
        .catch(({ graphQLErrors, networkError }) =>
          this.$Notice.error({
            title: this.$t("ERROR"),
            desc: JSON.stringify(networkError || graphQLErrors),
            duration: 0
          })
        )
        .finally(() => (this.loading = false));
    }
  }
};
</script>
