<template>
  <BreadcrumbLink
    :to="{ name: 'userGeneralTab', params: { userId: id } }"
    class="block"
    :assets="{ user: name }"
  >
    <Card
      class="cursor-pointer h-full"
      :class="{ 'border-yellow-500': !qlikUserId }"
    >
      <div slot="title" class="flex items-center">
        <fa icon="user" size="lg" class="mr-2"></fa>
        <div class="inline-block truncate flex-grow pt-1">
          <a
            :href="`mailto:${email}`"
            @click.stop
            class="text-base text-blue-500 leading-none"
          >
            {{ email }}
          </a>
          <br />
          <span class="text-sm text-gray-500 leading-tight">{{ name }}</span>
        </div>
        <div class="ml-auto">
          <Tooltip v-if="blocked" :content="$t('USER_BLOCKED')">
            <fa icon="do-not-enter" size="lg" class="text-red-600"></fa>
          </Tooltip>
          <Tooltip v-if="!qlikUserId" :content="$t('NO_DAS_ACCESS')">
            <fa
              icon="exclamation-triangle"
              size="lg"
              class="text-yellow-500"
            ></fa>
          </Tooltip>
        </div>
      </div>
      <div class="h-24 flex flex-col">
        <div class="flex-grow">
          <Roles :roles="roles"></Roles>
        </div>
        <div class="mt-auto flex items-end">
          <Auth0Tag
            :m2m-client-id="auth0M2mClientId"
            :user-id="auth0UserId"
          ></Auth0Tag>
          <DASTag :user-id="qlikUserId"></DASTag>
          <LastLogin
            :date="lastLogin"
            class="ml-auto text-xs text-gray-500 italic"
          />
        </div>
      </div>
    </Card>
  </BreadcrumbLink>
</template>

<script>
import BreadcrumbLink from "@/components/BreadcrumbLink/BreadcrumbLink.vue";
import LastLogin from "@/components/LastLogin/LastLogin.vue";
import Roles from "./components/Roles/Roles.vue";
import Auth0Tag from "./components/Auth0Tag/Auth0Tag.vue";
import DASTag from "./components/DASTag/DASTag.vue";

export default {
  components: { BreadcrumbLink, LastLogin, Roles, Auth0Tag, DASTag },
  props: {
    id: { type: [Number, String], required: true },
    name: { type: String, required: true },
    email: { type: String, required: true },
    lastLogin: { type: String, default: null },
    auth0UserId: { type: String, default: null },
    auth0M2mClientId: { type: String, default: null },
    qlikUserId: { type: String, default: null },
    roles: { type: Array, required: true }
  },
  computed: {
    blocked: () => false
  }
};
</script>

<style scoped></style>
