<template>
  <router-link :to="{ name: 'onboardUser' }" class="block">
    <Card class="cursor-pointer h-full flex items-center justify-center">
      <div class="text-center">
        <fa icon="plus" size="6x" class="mb-2"></fa><br />
        <span>{{ $t("ONBOARD_A_NEW_USER") }}</span>
      </div>
    </Card>
  </router-link>
</template>

<script>
export default {};
</script>

<style scoped></style>
