<template>
  <div>
    <div
      v-for="{ id, name, tenant: { name: tenantName } } in truncatedRoles"
      :key="id"
      class="px-1 h-full"
    >
      <Tooltip
        :disabled="!name"
        :content="ROLES[name] ? $t(ROLES[name].key) : name"
      >
        <img
          class="h-4 inline-block mr-1"
          :src="
            require(`@/assets/${
              ROLES[name] ? ROLES[name].icon : 'logo-unknown.png'
            }`)
          "
          alt="Product logo"
        />
        <span class="truncate">{{ tenantName }}</span>
      </Tooltip>
    </div>
    <div v-if="numberOfExtraRoles" class="px-1">
      <img
        class="h-4 inline-block mr-1"
        src="@/assets/logo-unknown.png"
        alt="Product logo"
      />
      <span>{{ $t("X_MORE", { numberOfExtraRoles }) }}</span>
    </div>
    <div v-if="!roles.length" class="text-center">
      <fa icon="exclamation-triangle" size="2x" class="text-yellow-500"></fa>
      <div>{{ $t("NO_ROLES") }}</div>
    </div>
  </div>
</template>

<script>
import { ROLES } from "@/assetsMapping.const.js";

export default {
  props: {
    roles: { type: Array, default: () => [] }
  },
  computed: {
    truncatedRoles() {
      return this.roles.length > 3 ? this.roles.slice(0, 2) : this.roles;
    },
    numberOfExtraRoles() {
      return this.roles.length - this.truncatedRoles.length;
    },
    ROLES: () => ROLES
  }
};
</script>

<style scoped></style>
